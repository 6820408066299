<template>
  <div class="members-directory marketing-phone">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.push({ name: 'MarketingCampaign' })" class="btn customBtn pattern-btn white--text">
            <span> {{ $t("marketingCampaign.back") }}</span>
          </button>
          <h3 class="box-title green-text">
            {{ $t("marketingCampaign.historyTitle") }}
          </h3>
        </div>
        <v-spacer />
        <div class="box-top-right">
          <h4 class="car-count">
            <span> {{ (marketingCampaignSearch.meta && marketingCampaignSearch.meta.total) || 0 }}</span>
          </h4>
          <div class="pa-0 d-flex word-search">
            <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
              :placeholder="$t('posts.searchForWords')" solo @input="fetchMarketingCampaign" append-icon="mdi-magnify">
            </v-text-field>
          </div>
        </div>
      </div>
      <div class="custom-card inside-model-box">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :headers="headers" :items="marketingCampaignSearch.data"
            :single-expand="singleExpand" :expanded.sync="expanded" fixed-header :disable-sort="true"
            :show-expand="false" class="color-light-green" :loading="loading" :page.sync="page"
            :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
            <template v-slot:item.created_at="{ item }">
              <span class="datebreak date-time">
                <v-icon aria-hidden="false">
                  mdi-clock-time-five-outline
                </v-icon>
            <span>{{ item.created_at | MMDDYYdatefilter }}</span>
              </span>
            </template>
            <template v-slot:item.message="{ item }">
              {{ item.message }}
            </template>
            <template v-slot:item.media="{ item }">
              <div v-if="item.media && item.media.url" class="mt-2 text-pre-wrap">
                <div class="post-inventory-div">
                  <span style="margin-right:5px;">
                    <img :src="item.media.url" @click="imageSlider = true; images = [item.media];" alt="" width="100px"
                      height="80px">
                  </span>
                </div>
              </div>
            </template>
            <template v-slot:item.cost="{ item }">
              ${{ item.cost }}
            </template>
            <template v-slot:item.failed_count="{ item }">
              {{ item.failed_count }}
            </template>
            <template v-slot:item.success_count="{ item }">
              {{ item.success_count }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.type }}
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
          <v-dialog hide-overlay :attach="true" v-model="imageSlider" max-width="95%" width="auto" margin="0"
            :content-class="'image-slider custom-modal-box'" persistent>
            <v-carousel class="carousel-wrapper post-img-wrapper">
              <span class="carousel-close-icon" @click="imageSlider = false">
                <v-icon>mdi-close</v-icon>
              </span>
              <v-carousel-item v-for="(item, i) in images" :key="i">
                <img :src="item.url" style="width: 600px; height: auto" :alt="item.url" eager />
              </v-carousel-item>
            </v-carousel>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ITEMS_PER_PAGE } from "@/constants/common";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",


      images: [],
      imageSlider: false,
      loading: false,
      page: 1,
      searchText: "",
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      marketingCampaignSearch: "marketingCampaign/getMarketingCampaignSearch"
    }),
    headers() {
      return [
        {
          text: this.$t("marketingCampaign.campaignTable.date"),
          align: "left",
          value: "created_at",
          width: "10%",
        },
        {
          text: this.$t("marketingCampaign.campaignTable.message"),
          align: "left",
          value: "message",
          width: "35%",
        },
        {
          text: this.$t("marketingCampaign.campaignTable.image"),
          align: "left",
          value: "media",
          width: "15%",
        },
        {
          text: this.$t("marketingCampaign.campaignTable.cost"),
          align: "left",
          value: "cost",
          width: "10%",
        },
        {
          text: this.$t("marketingCampaign.campaignTable.totalFailed"),
          align: "left",
          value: "failed_count",
          width: "10%",
        },
        {
          text: this.$t("marketingCampaign.campaignTable.totalSuccess"),
          align: "left",
          value: "success_count",
          width: "10%",
        },
        {
          text: this.$t("marketingCampaign.campaignTable.type"),
          align: "left",
          value: "type",
          width: "10%",
        },
      ];
    }
  },
  async mounted() {
    await this.fetchMarketingCampaign();
  },
  methods: {
    ...mapActions({
      getMarketingCampaign: "marketingCampaign/getMarketingCampaign",
    }),

    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchMarketingCampaign(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchMarketingCampaign(); // Fetch new data based on the current page
      }
    },

    async fetchMarketingCampaign() {
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        await this.getMarketingCampaign({
          search: this.searchText,
          page: this.currentPage,
          items_per_page: this.perPage,
        });

        if (this.marketingCampaignSearch.meta) {
          this.totalEntries = this.marketingCampaignSearch.meta.total;
          this.itemsPerPage = parseInt(this.marketingCampaignSearch.meta.per_page);
          this.pageCount = this.marketingCampaignSearch.meta.last_page;
        } else {
          this.itemsPerPage = this.marketingCampaignSearch.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.v-dialog__content {
  position: absolute;
}

.post-inventory-div {
  display: flex;
  align-items: center;
}
</style>